import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

const Hero = styled.section`
  position: relative;
  background: #0085ad;
`;

const HeroContent = styled(Container)`
  z-index: 1;
  color: white;
  padding-top: 9rem;
  @media (min-width: 1024px) {
    padding-top: 12rem;
  }
  h1 {
    margin-bottom: 1.5rem;
  }
`;
const ArrowOverlay = styled.div`
  background-image: url("/images/arrow_overlay.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 16rem;
  height: 12rem;
  left: calc(100% - 16rem);
  bottom: -50%;
  @media (min-width: 768px) {
    left: calc(100% - 20rem);
  }
  @media (min-width: 1024px) {
    width: 20rem;
    height: 12rem;
  }
`;

const PageHero = ({ parent, title }) => {
  return (
    <Hero className={parent}>
      <HeroContent>
        <Row>
          <Col>
            {title === "Submit an Abstract" ? (
              <span>
                <h1 className="mb-0">{title}</h1>
                <h5 className="mt-3 mb-5 font-italic">
                  <strong> Deadline: May 31, 2025</strong>
                </h5>
              </span>
            ) : (
              <h1>{title}</h1>
            )}
            {/* <ArrowOverlay /> */}
          </Col>
        </Row>
      </HeroContent>
    </Hero>
  );
};

export default PageHero;
